import { createAction, props } from '@ngrx/store';
import { SiglarUser } from 'src/types';

// eslint-disable-next-line no-shadow
export const enum UserActions {
  LoadUsers = '[USER] Load Users',
  LoadUsersSuccess = '[USER] Load Users Success',
  LoadUsersFailure = '[USER] Load Users Failure',
  LoadOpsUsers = '[USER] Load Operations Users',
  LoadOpsUsersSuccess = '[USER] Load Operations Users Success',
  LoadOpsUsersFailure = '[USER] Load Operations Users Failure',
  CreateUser = '[USER] Create User',
  CreateUserSuccess = '[USER] Create User Success',
  CreateUserFailure = '[USER] Create User Failure',
  UpdateUser = '[USER] Update User',
  UpdateUserSuccess = '[USER] Update User Success',
  UpdateUserFailure = '[USER] Update User Failure',
  DeleteUser = '[USER] Delete User',
  DeleteUserSuccess = '[USER] Delete User Success',
  DeleteUserFailure = '[USER] Delete User Failure',
  OpenDeleteUserPrompt = '[USER] Open Delete User Prompt',
  SetSearchFilter = '[USER] Set Search Filter',
  ClearSearchFilter = '[USER] Clear Search Filter',
}

/** Load users */
export const loadUsers = createAction(UserActions.LoadUsers);
export const loadUsersSuccess = createAction(UserActions.LoadUsersSuccess, props<{ users: SiglarUser[] }>());
export const loadUsersFailure = createAction(UserActions.LoadUsersFailure, props<{ error: any }>());

/** Load operations users */
export const loadOpsUsers = createAction(UserActions.LoadOpsUsers);
export const loadOpsUsersSuccess = createAction(UserActions.LoadOpsUsersSuccess, props<{ users: SiglarUser[] }>());
export const loadOpsUsersFailure = createAction(UserActions.LoadOpsUsersFailure, props<{ error: any }>());

/** Create user */
export const createUser = createAction(UserActions.CreateUser, props<{ user: any }>());
export const createUserSuccess = createAction(UserActions.CreateUserSuccess, props<{ response: any }>());
export const createUserFailure = createAction(UserActions.CreateUserFailure, props<{ error: any }>());

/** Update user */
export const updateUser = createAction(UserActions.UpdateUser, props<{ user: any }>());
export const updateUserSuccess = createAction(UserActions.UpdateUserSuccess, props<{ response: any }>());
export const updateUserFailure = createAction(UserActions.UpdateUserFailure, props<{ error: any }>());

/** Delete user */
export const deleteUser = createAction(UserActions.DeleteUser, props<{ email: string }>());
export const deleteUserSuccess = createAction(UserActions.DeleteUserSuccess, props<{ response: any }>());
export const deleteUserFailure = createAction(UserActions.DeleteUserFailure, props<{ error: any }>());

/** Open Delete User Prompt */
export const openDeleteUserPrompt = createAction(UserActions.OpenDeleteUserPrompt, props<SiglarUser>());

/** Search filter */
export const setSearchFilter = createAction(UserActions.SetSearchFilter, props<{ searchValue: string }>());
export const clearSearchFilter = createAction(UserActions.ClearSearchFilter);
